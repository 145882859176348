import * as React from "react";
import Typography from '@material-ui/core/Typography';
import 'fontsource-roboto';

import { w3cwebsocket as W3CWebSocket } from "websocket";


class CenterCards extends React.Component {

	constructor(props) {
		super(props);
        this.renderTable = this.renderTable.bind(this);
        this.card2Img = this.card2Img.bind(this);
	}

    card2Img(card) {
        //TODO need logic here to map pic => img url
        const BASE_PATH = "https://unpkg.com/cardsJS@1.1.1/cards/";
        const card2imgurl = (c) => {
            var suit = c.suit.toUpperCase()[0];
            var value = c.value.toString();

            if (value == "11") {
                value = "J";
            }

            if (value == "12") {
                value = "Q";
            }
            
            if (value == "13") {
                value = "K";    
            }
               
            if (value == "14") {
                value = "A";
            }
            return value.concat(suit);
        }

        return <img height="200" width="140" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{padding: "5px"}} />
    }

    renderTable() {
        const BASE_PATH = "https://unpkg.com/cardsJS@1.1.1/cards/";

        //TODO(brianj) make the sizing of these better
        //this.props.cards.map(card => <th> <img src={BASE_PATH+card}/> </th>
        return (
                this.props.cards.map(card => this.card2Img(card))
        );
    }

    render () {
       return (
		<div>

        <Typography variant="h4" component="h5" gutterBottom style={{fontWeight: 'bold'}}>
        It's {this.props.playerTurnName}'s turn 
        </Typography>
        <table>
        <tbody>
            <tr>
            {this.renderTable()}
            </tr>
        </tbody>
      </table> 
	</div>
      );
    }
}

export default CenterCards;
