import * as React from "react";

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import 'fontsource-roboto';


import { w3cwebsocket as W3CWebSocket } from "websocket";


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class OpponentsCards extends React.Component {

	constructor(props) {
		super(props);
        this.renderTable = this.renderTable.bind(this);
        this.renderCardTable = this.renderCardTable.bind(this);
	}

    renderCardTable(cards) {
        const BASE_PATH = "https://unpkg.com/cardsJS@1.1.1/cards/";
        const card2imgurl = (c) => {
            var suit = c.suit.toUpperCase()[0];
            var value = c.value.toString();

            if (value == "11") {
                value = "J";
            }

            if (value == "12") {
                value = "Q";
            }
            
            if (value == "13") {
                value = "K";    
            }
               
            if (value == "14") {
                value = "A";
            }
            return value.concat(suit);
        }

        return cards.map(card =>
                <th>
                <img height="80" width="56" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{padding: "5px"}}/>
                </th>
                );

    }

    renderTable() {
        return (
            this.props.players.sort(
				function(a, b){
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				}).map( player =>
                    <div>
                            <table>
                            <tr>
                            <th>
                                
                            <Typography variant="h5" component="h6" gutterBottom>
                            {player.name}
                            </Typography>

                            <Typography variant="body1" component="body2" gutterBottom>
                                Current Score: {player.score}
                            </Typography>
                            </th>
                            {this.renderCardTable(player.cardsForPoints)}
                            </tr>
                            </table>
                            <Divider />
                    </div>
                )
       );
    }

    render () {
       const {classes} = this.props;
       return (
           <div className={classes.root}t>
            {this.renderTable()}            
           </div>
      );
    }
}

export default withStyles(styles)(OpponentsCards);
