import * as React from "react";

import { w3cwebsocket as W3CWebSocket } from "websocket";


class UserCards extends React.Component {

	constructor(props) {
		super(props);
        this.renderTable = this.renderTable.bind(this);
        this.renderCardTable = this.renderCardTable.bind(this);
	}

    renderCardTable(cards) {
        const imageClick = (c) => {
            this.props.connection.send(JSON.stringify({
                playerID: this.props.playerID,
                actionType: "move_card_to_center",
                card: c 
            }));
        }
        
        const card2imgurl = (c) => {
            var suit = c.suit.toUpperCase()[0];
            var value = c.value.toString();

            if (value == "11") {
                value = "J";
            }

            if (value == "12") {
                value = "Q";
            }
            
            if (value == "13") {
                value = "K";    
            }
               
            if (value == "14") {
                value = "A";
            }
            return value.concat(suit);
        }

        console.log(this.props.centerCardSuit);
        console.log(cards);

        // TODO abstract this logic out into a shared function
        const BASE_PATH = "https://unpkg.com/cardsJS@1.1.1/cards/";
        if (this.props.isPlayerTurn) {
            
            // In this case the player is either the first to go for this hand OR has no cards of the given suit left => all cards are fair game
            if (!this.props.centerCardSuit || this.props.cards.filter(c => c.suit == this.props.centerCardSuit).length == 0) {
                return cards.map(card =>
                        <th>
                            <img height="160" width="112" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{padding: "5px"}}  onClick={() => imageClick(card)} />
                        </th>
                );
            } else {
                // In this case the user should only be able to select cards matching the suit that started the hand
                return cards.map((card) =>
                        (card.suit == this.props.centerCardSuit) ?
                        (
                        <th>
                            <img height="160" width="112" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{padding: '5px'}}  onClick={() => imageClick(card)} />
                        </th>
                        ):
                        (
                        
                        <th>
                            <img height="160" width="112" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{padding: '5px', opacity: '.5'}} />
                        </th>
                        )
                );
            }
        } else {
            // Make cards unclickable if it isn't your turn and decrease their opaicty to 50%
            return cards.map(card =>
                    <th>
                        <img height="160" width="112" src={BASE_PATH.concat(card2imgurl(card).concat(".svg"))} style={{
                            padding: "5px",
                            opacity: ".5"
                        }}/>
                    </th>
            );
        }
    }

    renderTable() {
        return (
                <table>
                    <tr>
                        {this.renderCardTable(this.props.cards.slice(0, 9))}
                    </tr>
                    <tr>
                        {this.renderCardTable(this.props.cards.slice(10, this.props.cards.length))}
                    </tr>
                </table>
        );
    }

    render () {
       if (!this.props.cards) {
        return <div />
       }


       if (this.props.isPlayerTurn) {
           return (
               <div>
               <h1> Your Cards </h1>
               <p> It's your turn! </p>
                {this.renderTable()}            
               </div>
              );
       } else {
           return (
               <div>
               <h1> Your Cards </h1>
                {this.renderTable()}            
               </div>
              );
       }
    }
}

export default UserCards;
