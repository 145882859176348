import * as React from "react";
import './App.css'

// Materiaul UI imports
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { sizing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom component imports 
import DenseAppBar from './components/DenseAppBar.js';
import CenterCards from './components/CenterCards.js';
import OpponentsCards from './components/OpponentsCards.js';
import UserCards from './components/UserCards.js';

//Websocket imports
import { w3cwebsocket as W3CWebSocket } from "websocket";

// etc
import { v4 as uuidv4 } from 'uuid';
import 'fontsource-roboto';



const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
       margin: theme.spacing.unit,
  },
  paper: {
	...theme.typography.body1,
    padding: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'normal',
	elevation: 3
  },
});

class App extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          // TODO need to think of a better way of keeping the local state of which player we are
          localPlayer: {
            playerIsWaiting: false 
          }
      }

      this.markPlayerAsReady = this.markPlayerAsReady.bind(this);
      this.recordPlayerNameInput = this.recordPlayerNameInput.bind(this);
      this.getWaitingPlayers = this.getWaitingPlayers.bind(this);
      this.startGame = this.startGame.bind(this);
      this.startRound = this.startRound.bind(this);
  }
    
  //TODO(brianj) update this when we are ready to NOT have hardcoded state!
  connection = new WebSocket("ws://api.chineseheartsgame.com/chat");

  componentDidMount(){
    // listen to onmessage event
    this.connection.onmessage = evt => { 
      // add the new message to state
        console.log(evt.data);
        console.log(JSON.parse(evt.data));
        this.setState({
            // Update the game state based on what's in the message
            gameState: JSON.parse(evt.data)
      })
    };

    this.connection.onopen = () => {
      setInterval(() => {
        setTimeout(this.connection.send(JSON.stringify({
            playerID: this.state.localPlayer.id || "",
            actionType: "heartbeat"
        })), 1000);
      },4000);	
    }

	this.connection.onclose = () => {
		console.log("WEBSOCK CLOSED ... reconnecting");
		// TODO add better client err + exp backoff?
		this.connection = new WebSocket("ws://192.186.1.155:9000/chat");
	}

	// TODO this is a really dumb hack to prevent this code from running before the ws connection is ready 	
    setTimeout(_ => { 
			this.connection.send(JSON.stringify({
			playerID: "setup_ping",
			actionType: "setup_ping"
		}))
	}, 5000);

	
    
  }

  markPlayerAsReady() {
    console.log(this.state.playerName.concat(" is ready"));
    // var localPlayer = this.state.localPlayer;
    // localPlayer.playerIsWaiting = true;
    var playerID = this.state.playerName.concat("_").concat(uuidv4().toString())

    this.setState({
        localPlayer: {
            id: playerID,
            name: this.state.playerName,
            // TODO I don't this we need this ??
            playerIsWaiting: true
        }
    });

    this.connection.send(JSON.stringify({
        playerID: playerID,
        actionType: "player_register_for_game",
        playerName: this.state.playerName,
        card: null
    }));
  }

  recordPlayerNameInput(e) {
    this.setState({playerName: e.target.value});
  }

  getWaitingPlayers() {
      return this.state.gameState.players.map(
        player => 
            <p> {player.name} </p>
      );

  }

  startGame() {
    this.connection.send(JSON.stringify({
        playerID: this.state.localPlayer.id,
        actionType: "start_game"
    }));
  }

  startRound() {
    this.connection.send(JSON.stringify({
        playerID: this.state.localPlayer.id,
        actionType: "start_next_round"
    }));
  }

  render () {
      const {classes} = this.props;
      if (!this.state.gameState) {
        return(
            <div>

           <DenseAppBar />
           <br />
            <Box width="50%" height = "50%"  m="auto">

                        <Paper className={classes.paper}>
            <p> One moment please ... connecting to game server </p>
			 <CircularProgress />
             </Paper>
             </Box>
             </div>

		);
 
      }

      if(this.state.gameState.isBetweenRounds && this.state.gameState.isActiveGame) {
        return (
        <div>
           <DenseAppBar />
           <br />
            <Box width="50%" height = "50%"  m="auto">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h3" gutterBottom>
                    Round {this.state.gameState.roundNumber} Results 
                </Typography> 
                
                <Typography variant="h5" component="h6" gutterBottom>
                   🐷 {this.state.gameState.players.filter(p =>
                            p.wasPigInPreviousRound
                    ).map(p => p.name)[0]} was the pig last round. Shame on them for being so fat. 🐷
                </Typography> 
                

              {/* TODO make this it's own component */}
              <OpponentsCards players = {this.state.gameState.players} />
            {/* TODO change onclick function for this button */}
            <Button style={{backgroundColor: "#66f679" }} variant="contained" color="primary" className={classes.button} onClick = {this.startRound}> 
            Start Next Round!
            </Button>
                
             </Paper>
             </Box>
             </div>
       );
      }

      if(this.state.gameState.isGameOver) {
        return (
        <div>
           <DenseAppBar />
           <br />
            <Box width="50%" height = "50%"  m="auto">
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h3" gutterBottom>
                    Game Over
                </Typography> 
                
                <Typography variant="h5" component="h6" gutterBottom>
                   🐷 {this.state.gameState.players.filter(p =>
                            p.score >= 1000
                    ).map(p => p.name)[0]} has exploded because they are so fat!! So shameful!! 🐷
                </Typography> 
                

              {/* TODO make this it's own component */}
              <OpponentsCards players = {this.state.gameState.players} />
            {/* TODO change onclick function for this button */}
            <Button style={{backgroundColor: "#66f679" }} variant="contained" color="primary" className={classes.button} onClick = {this.startGame}> 
                Play Again?
            </Button>
                
             </Paper>
             </Box>
             </div>
       );
      }



      if (this.state.gameState.isActiveGame) {
		  console.log("LOG TOTAL STATE")
          console.log(this.state);
          return (
            <div>
                {/* Nav bar */}
               <DenseAppBar />
               <br />
               <Grid container spacing={1}>
                    {/*Grid element for center cards */}
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                          <CenterCards
                          cards = {this.state.gameState.cardsInCenter.map(c => c.card)}
                          playerTurnName={this.state.gameState.players.filter(player => player.id == this.state.gameState.playerTurn)[0].name} />

                      </Paper>
                    </Grid>
                    {/*Grid element for opponent cards + scores */}
                    <Grid item xs={6}>
                      <Paper className={classes.paper}>
                          <OpponentsCards players = {this.state.gameState.players} />
                      </Paper>
                    </Grid> 
                    {/*Grid element for user's cards + score etc. */}
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <UserCards 
                            cards = {
                                this.state.gameState.players.filter(
                                        player => player.id == this.state.localPlayer.id
                                ).map(player => player.cards)[0]
                            }
                            connection = {this.connection}
                            isPlayerTurn = {this.state.gameState.playerTurn == this.state.localPlayer.id}
                            centerCardSuit = {
                                ((this.state.gameState.cardsInCenter.length == 0 || this.state.gameState.cardsInCenter === undefined) ? null : 
                                this.state.gameState.cardsInCenter[0].card.suit )
                            }

                            playerID = {this.state.localPlayer.id}
                        />
                      </Paper>
                    </Grid>
                 </Grid>
                </div>
          );
      } else if (this.state.localPlayer.playerIsWaiting) {
            return (
                    <div>
                        <DenseAppBar />
                       <br />
						<Box width="50%" height="50%" m="auto">
                        <Paper className={classes.paper}>
                            <Typography variant="h4" component="h3" gutterBottom>
								Currently in waiting room
							</Typography> 
                            <p> You can start a game once you have at least 3 players in the waiting room! </p>
                            <Divider />
                            {this.getWaitingPlayers()}

                        <Button style={{backgroundColor: "#66f679" }}type="submit" variant="contained" color="primary" className={classes.button} onClick = {this.startGame} disabled={this.state.gameState.numPlayers < 3}>
                        Start Game!
                        </Button>
                        </Paper>
						</Box>
                    </div>
            );
      
      } else {
          // Else if there is not an active game flash a screen prompting 
          return (
				<div>
					<DenseAppBar />
					<br />
					<Box width="50%" height="50%" m="auto">
					<Paper className={classes.paper}>
					 <form className={classes.root} autoComplete="off" onSubmit={this.markPlayerAsReady}>

                            <Typography variant="h4" component="h3" gutterBottom>
						You're just in time! The game hasn't started yet! </Typography>
						<p> Tell us a bit about yourself to get started </p>
						<br/>
						<TextField required id="outlined-basic" label="Name" variant="outlined" onChange={this.recordPlayerNameInput} />

                        <Button type="submit" variant="contained" color="primary" className={classes.button} style={{
                            backgroundColor: "#66f679"  
                        }}>
                        Join Game!
                     </Button>
					</form> 
					</Paper>
					</Box>
				</div>
          );

      }
  }
}

export default withStyles(styles)(App);
